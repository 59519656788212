.About-Container {
  background-color: #fbfaff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem;
}
.About-Container .Container {
  max-width: 27rem;
  margin-left: 4rem;
  gap: 2rem;
  align-items: start;
}
.About-Container a {
  display: block;
  text-decoration: underline;
}
@media screen and (max-width: 900px) {
  .About-Container img {
    width: 10rem;
  }
}
