.Applications-Container {
  background-color: #fbfaff;
}
.Applications-Container .Cards-Container {
  display: flex;
  width: 60rem;
  gap: 2rem;
  margin-bottom: 1rem;
}
.Card-Template.Outdoor p {
  margin-top: 0.75rem;
  margin-right: auto;
  line-height: 1.5;
}
@media screen and (max-width: 900px) {
  .Applications-Container .Cards-Container {
    flex-direction: column;
    width: unset;
  }
}
