hr {
  width: 60rem;
  border-color: #333;
  margin-top: 4rem;
}
@media screen and (max-width: 900px) {
  hr {
    width: 20rem;
  }
}
