.Navbar {
  display: flex;
  align-items: center;
  width: 60rem;
}
.Navbar .Logo {
  margin-right: 5rem;
}
.Navbar a {
  padding: 1rem;
}
@media screen and (max-width: 900px) {
  .Navbar {
    justify-content: center;
    width: unset;
  }
  .Navbar .Logo {
    display: none;
  }
}
