.App {
  display: flex;
  align-items: center;
  flex-direction: column;
}
a {
  color: inherit;
  text-decoration: none;
  transition: 0.2s ease;
}
a:hover {
  color: #333;
}
p,
a {
  font-size: 18px;
}
.Container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.Section-Header {
  font-size: 2rem;
  font-weight: 700;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.Footer {
  background-image: linear-gradient(45deg, #5f35fb, #7b40f4);
  height: 16vh;
  width: 100%;
  color: white;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
