.Card-Template {
  background-image: linear-gradient(55deg, #5f35fb, #7b40f4);
  color: white;
  border-radius: 0.5rem;
  padding: 1.6rem;
  padding-top: 3rem;
  width: 29rem;
  height: 29rem;
}
.Card-Template p {
  margin-top: 2rem;
  margin-right: auto;
  line-height: 1.5;
  padding-right: 1.25rem;
}
.Card-Template h3 {
  font-size: 20px;
  margin-right: auto;
}
.Card-Template .Card-Template-Image {
  margin-left: auto;
  margin-top: auto;
}
@media screen and (max-width: 900px) {
  .Card-Template {
    width: unset;
    height: unset;
  }
}
