/* General Card Styles */
.Proposition-Container .Cards-Container {
  display: flex;
  justify-content: center;
  max-width: 60rem;
  padding-bottom: 4rem;
}
.Proposition-Container .Card {
  justify-content: start;
  text-align: center;
  padding: 1rem 1rem 0 1rem;
}
.Proposition-Container .Card-Image {
  width: 70px;
  height: 70px;
  margin-bottom: 1rem;
  border-radius: 50%;
}
.Proposition-Container .Card h3 {
  font-size: 21px;
  line-height: 27px;
}
/* Specific Card Styles */
#Fast h3 {
  color: #663afa;
}
#Accurate h3 {
  color: #ffc143;
}
#Autonomous h3 {
  color: #7fd65a;
}
#Easy h3 {
  color: #df4a4c;
}
@media screen and (max-width: 900px) {
  .Cards-Container {
    flex-direction: column;
  }
}
