.Header-Container {
  background: no-repeat scroll top center / cover
    url("../../assets/imgs/Background.png");
  min-height: 65vh;
  padding-top: 1rem;

  background-color: #fbfaff;
}
/* Content Container Styles */
.Header-Content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60rem;
  min-height: 30rem;
}
.Header-Content p {
  margin-top: 1rem;
  line-height: 1.5rem;
}
.Header-Content h2 {
  font-size: 60px;
  font-weight: 400;
  line-height: 78px;
}
@media screen and (max-width: 900px) {
  .Header-Content {
    flex-direction: column;
    width: 20rem;
  }
  .Header-Content h2 {
    font-size: 3rem;
  }
  .Header-Content img {
    width: 20rem;
    margin-top: 1rem;
  }
}
